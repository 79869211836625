// import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <br/><br/>
      website coming soon. xxxxx<br/><br/>

      <span style={{color: "#0000AA"}}><strong>email:</strong> <a style={{color: "#0000AA"}} href="mailto:info@silvereye.studio" target="_blank" rel="noopener noreferrer">info@silvereye.studio</a></span>
    </div>
  );
}

export default App;
